import { ReactComponent as TwitterLogo } from 'img/socials/twitter.svg'
import { ReactComponent as RedditLogo } from 'img/socials/reddit.svg'
import { ReactComponent as CTLogo } from 'img/socials/ct.svg'

const quotesList = [
  {
    link: 'https://twitter.com/iohk_charles/status/1132374603748270090?lang=en',
    text: 'Ergo is the spiritual successor to Bitcoin.',
    getLogo: () => <TwitterLogo />,
    socialName: 'twitter',
  },
  {
    link: 'https://twitter.com/iohk_charles/status/944941679458492417?lang=en',
    text: 'The project has one of his favorite technologists. - he was referring Alexander Chepurnoy',
    getLogo: () => <TwitterLogo />,
    socialName: 'twitter',
  },
  {
    link: 'https://cointelegraph.com/news/charles-hoskison-reveals-his-9-favorite-crypto-projects',
    text: "It's one of the most revolutionary cryptocurrencies ever built. Got so many crazy ideas like non-outsourceable puzzles and sigma protocols and pruning the blockchain and roller chains. All this crazy stuff. ",
    getLogo: () => <CTLogo />,
    socialName: 'ct',
  },
  {
    link: 'https://www.reddit.com/r/cardano/comments/m3j3ry/charles_hoskinson_on_ergo_cardano_ada_erg/',
    text: 'Ergo is one of the most interesting projects in the space',
    getLogo: () => <RedditLogo />,
    socialName: 'reddit',
  },
  {
    link: 'https://www.reddit.com/r/ergonauts/comments/ruszkp/charles_hoskinson_shouted_out_ergo_this_morning/',
    text: "Ergo is an extremely exciting project run by IOHK alums Dmitry and Alex. I'm happy to see it grow and evolve",
    getLogo: () => <RedditLogo />,
    socialName: 'reddit',
  },
  {
    link: 'https://www.reddit.com/r/CryptoCurrency/comments/ppn6la/charles_hoskinson_speaks_highly_of_alex_chepurnoy/',
    text: "It's one of the most revolutionary cryptocurrencies ever built. Got so many crazy ideas like non-outsourceable puzzles and sigma protocols and pruning the blockchain and roller chains. All this crazy stuff. Even has a proof of no premine.",
    getLogo: () => <RedditLogo />,
    socialName: 'reddit',
  },
  {
    link: 'https://www.reddit.com/r/CryptoCurrency/comments/q5nub6/ergo_its_one_of_the_most_revolutionary/',
    text: 'It’s one of the most revolutionary cryptocurrencies ever built. It should be a top 10 coin or top 15 coin',
    getLogo: () => <RedditLogo />,
    socialName: 'reddit',
  },
]

export default quotesList
