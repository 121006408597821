const cardList = [
  {
    text: [
      {
        p: 'No venture capital investments',
      },
      {
        p: 'No ICO (initial coin offering)',
      },
      {
        p: 'No pre-mining of tokens',
      },
    ],
  },
  {
    text: [
      {
        p: 'Ergo was fairly launched. No one had any economic advantage with the launch of the blockchain.',
      },
    ],
  },
  {
    text: [
      {
        p: 'The first ERG tokens were only released via the mining protocol to a miner',
      },
    ],
  },
  {
    text: [
      {
        p: "The mining algorithm (Autolykos v2) is ASIC resistent, allowing anyone with consumer grade GPU's the ability to participant in mining/maintaining the network. This means even a personal PC can earn a person passive income when they are not using their GPU for other functions.",
      },
    ],
  },
  {
    text: [
      {
        p: 'The blockchain is designed to be truly accessible to anyone, not just the rich. It is meant for the everyday, average individual, and it aims to inspire creating a financial market that empowers the lower and middle classes.',
      },
    ],
  },
  {
    text: [
      {
        p: 'It has unique technologies: NIPoPoWs, Storage Rent, Sigma Protocols, ErgoScript, optional privacy features, non-interactive/non-custodial mixer for tokens, a truly stable non-custodial crypto-backed stablecoin.',
      },
    ],
  },
  {
    text: [
      {
        p: 'The tooling and resources of Ergo are open source and future developers are encouraged to maintain that spirit.',
      },
    ],
  },
  {
    text: [
      {
        p: "It is a completely new and innovative blockchain that employs Bitcoin's UTXO ledger model while enabling smart contract functionality and deployment of decentalized applications",
      },
    ],
  },
]

export default cardList
