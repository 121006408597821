import { createGlobalStyle } from 'styled-components'
import VinilaRgExWoff from './fonts/Vinila-Rg-Ex.woff'
import VinilaRgExWoff2 from './fonts/Vinila-Rg-Ex.woff2'
import VinilaBdExWoff from './fonts/Vinila-Bd-Ex.woff'
import VinilaBdExWoff2 from './fonts/Vinila-Bd-Ex.woff2'
import VinilaLtExWoff from './fonts/Vinila-Lt-Ex.woff'
import VinilaLtExWoff2 from './fonts/Vinila-Lt-Ex.woff2'
import RobotRgWoff from './fonts/Roboto-Regular.woff'
import RobotRgWoff2 from './fonts/Roboto-Regular.woff2'

const Vinila = {
  regular: {
    woff2: VinilaRgExWoff2,
    woff: VinilaRgExWoff,
  },
  bold: {
    woff2: VinilaBdExWoff2,
    woff: VinilaBdExWoff,
  },
  light: {
    woff2: VinilaLtExWoff2,
    woff: VinilaLtExWoff,
  },
}

const Roboto = {
  regular: {
    woff2: RobotRgWoff2,
    woff: RobotRgWoff,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Vinila Extended';
    src: local('Vinila Extended'),
    url(${Vinila.regular.woff2}) format('woff2'),
    url(${Vinila.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Vinila Extended';
    src: local('Vinila Extended'),
    url(${Vinila.bold.woff2}) format('woff2'),
    url(${Vinila.bold.woff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Vinila Extended';
    src: local('Vinila Extended'),
    url(${Vinila.light.woff2}) format('woff2'),
    url(${Vinila.light.woff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
    url(${Roboto.regular.woff2}) format('woff2'),
    url(${Roboto.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
`

export default Fonts
