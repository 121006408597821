import React from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import authorPhoto from 'img/mainfest/author.png'
import paperImg from 'img/mainfest/paper.png'
import bottomBg from 'img/mainfest/bottom-bg.svg'
import paperImgLg from 'img/mainfest/paper-lg.png'
import paperImgSm from 'img/mainfest/paper-sm.png'
import bottomBgSm from 'img/mainfest/bottom-bg-sm.svg'
import useWindowSize from 'helpers/utils/useWindowSize'
import { ReactComponent as Decentralization } from 'img/mainfest/decentralization.svg'
import { ReactComponent as Secure } from 'img/mainfest/secure.svg'
import { ReactComponent as User } from 'img/mainfest/user.svg'
import { ReactComponent as Money } from 'img/mainfest/money.svg'
import { ReactComponent as Focus } from 'img/mainfest/focus.svg'
import { Title } from 'theme/theme'

interface Props {
  open: () => void
}

function Manifest(props: Props) {
  const { width } = useWindowSize()
  return (
    <Root id={'manifesto'}>
      <Container>
        {width > 1024 && <Paper />}
        {width > 1024 && <Bg />}
        <Title>Ergo’s Manifesto</Title>
        <SubTitle>
          RGO was designed to improve upon the modern monetary system,
          encouraging transparency and accessilbity with finanical instruments
          on the blockchain.
        </SubTitle>
        <Principles>
          <PrincipleTitle></PrincipleTitle>
          <List>
            <Row>
              <Rect>
                <Decentralization />
              </Rect>
              <Text>Decentralization</Text>
            </Row>
            <Row>
              <Rect>
                <Secure />
              </Rect>
              <Text>Open Permissionless and Secure</Text>
            </Row>
            <Row>
              <Rect>
                <User />
              </Rect>
              <Text>Created for Regular People</Text>
            </Row>
            <Row>
              <Rect>
                <Money />
              </Rect>
              <Text>A Platform for Contractual Money</Text>
            </Row>
            <Row>
              <Rect>
                <Focus />
              </Rect>
              <Text>Long-term Focus</Text>
            </Row>
          </List>
        </Principles>
        <DocRow>
          <Btn
            href={
              'https://ergoplatform.org/en/blog/2021-04-26-the-ergo-manifesto/'
            }
            target={'_blank'}
          >
            {width < 1024 ? 'Ergo’s Manifesto' : ' Read full document'}
          </Btn>
          <AuthorRow>
            <AuthorImg />
            <Info>
              <Name>Alexander Chepurnoy</Name>
              <Position onClick={props.open}>About author</Position>
            </Info>
          </AuthorRow>
        </DocRow>
        {width < 1024 && <Paper />}
        {width < 1024 && <Bg />}
      </Container>
    </Root>
  )
}

export default Manifest

const Root = styled.div`
  margin-top: 950px;
  padding-top: 100px;
  position: relative;
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 806px;
    padding-top: 80px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 0;
    padding-top: 60px;
  }
  ${Title} {
    margin-bottom: 24px;
    ${({ theme }) => theme.adaptive.sm} {
      margin-bottom: 20px;
    }
  }
`

const SubTitle = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 1.313rem;
  line-height: 150%;
  color: #ffffff;
  width: 613px;
  margin-bottom: 40px;
  ${({ theme }) => theme.adaptive.lg} {
    width: 511px;
    margin-bottom: 50px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    font-size: 0.875rem;
  }
`

const Principles = styled.div``

const PrincipleTitle = styled.div`
  font-weight: 400;
  font-size: 2rem;
  line-height: 110%;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 1.25rem;
  }
`

const List = styled.div`
  position: relative;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`

const Rect = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 20px;
  svg {
    width: 100%;
    height: 100%;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-right: 12px;
  }
`

const Text = styled.div`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 110%;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 1rem;
  }
`

const DocRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 44px;
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 40px;
  }
`

const Btn = styled.a`
  display: block;
  width: 246px;
  height: 56px;
  background: #ce4e17;
  border: 1px solid #ce4e17;
  border-radius: 170px;
  padding: 15px 23px;
  text-align: center;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 133%;
  color: #ffffff;
  margin-right: 32px;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.lg} {
    width: 224px;
    height: 53px;
    font-size: 1rem;
    margin-right: 32px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 146px;
    height: 48px;
    padding: 15px 13px;
    font-size: 0.75rem;
    margin-right: 20px;
  }
  &:hover {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
`

const AuthorRow = styled.div`
  display: flex;
  align-items: center;
`

const AuthorImg = styled.div`
  width: 60px;
  height: 60px;
  background: url('${authorPhoto}') center no-repeat;
  background-size: cover;
  margin-right: 12px;
  ${({ theme }) => theme.adaptive.lg} {
    width: 53px;
    height: 53px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const Info = styled.div``

const InfoTextCss = css`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.313rem;
  line-height: 150%;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 0.75rem;
  }
`

const Name = styled.div`
  ${InfoTextCss};
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const Position = styled.div`
  ${InfoTextCss};
  cursor: pointer;
  opacity: 0.5;
  ${({ theme }) => theme.adaptive.sm} {
    opacity: 1;
  }
`

const Paper = styled.div`
  position: absolute;
  width: 1160px;
  height: 979px;
  top: -48px;
  right: -295px;
  background: url('${paperImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.lg} {
    width: 587px;
    height: 780px;
    top: 85px;
    right: 0px;
    background: url('${paperImgLg}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    top: 630px;
    left: 50%;
    transform: translateX(-50%);
    width: 375px;
    height: 499px;
    background: url('${paperImgSm}') center no-repeat;
    background-size: cover;
  }
`

const Bg = styled.div`
  position: absolute;
  bottom: -146px;
  left: 50%;
  transform: translateX(-50%);
  width: 2132px;
  height: 308px;
  background: url('${bottomBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.lg} {
    bottom: -207px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 1852px;
    height: 200px;
    bottom: unset;
    top: 906px;
    left: -855px;
    transform: unset;
    background: url('${bottomBgSm}') center no-repeat;
    background-size: cover;
  }
`
