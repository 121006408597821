import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper'
import 'swiper/css'
import 'swiper/css/virtual'
import { Title, Description, Container } from 'theme/theme'
import historyList from '../../store/history'

const yearsList = ['2019', '2020', '2021', '2022']

function History() {
  const [activeYear, setActiveYear] = useState(yearsList[0])
  const [activeSlide, setActiveSlide] = useState(0)
  const [text, setText] = useState(historyList[0].text)
  const [link, setLink] = useState<string | undefined>(historyList[0].link)
  const slideCounts = 20
  // @ts-ignore
  const [swiper, setSwiper] = useState<Swiper>(null)

  const onYearChange = (year: string) => {
    setActiveYear(year)
    if (year !== activeYear) {
      let id = historyList.findIndex((elem) => elem.year === year)
      onSlideClick(id)
    }
  }

  useEffect(() => {
    if (swiper) {
      swiper.update()
    }
  }, [])

  const onSlideClick = (id: number) => {
    swiper.slideTo(id)
    onSlideChange(id)
  }

  const onSlideChange = (id: number) => {
    setActiveSlide(id)
    setText(historyList[id].text)
    if (historyList[id].link) {
      setLink(historyList[id].link)
    } else {
      setLink('')
    }
    if (historyList[id].year !== activeYear) {
      setActiveYear(historyList[id].year)
    }
  }

  return (
    <Root id={'history'}>
      <Container>
        <Title>History</Title>
        <Description>
          But there was a long way to the current success
        </Description>
        <YearsRow>
          {yearsList.map((year, idx) => (
            <YearButton
              type="button"
              active={activeYear === year}
              onClick={() => onYearChange(year)}
              key={idx}
            >
              {year}
            </YearButton>
          ))}
        </YearsRow>
        <Swiper
          onSwiper={(swiper) => setSwiper(swiper)}
          modules={[FreeMode]}
          freeMode={true}
          slidesPerView="auto"
          observer={true}
          observeParents={true}
          onSlideChange={(swiperCore) => {
            const { activeIndex } = swiperCore
            onSlideChange(activeIndex)
          }}
        >
          {historyList.map((item, idx) => (
            <SwiperSlide key={idx} onClick={() => onSlideClick(idx)}>
              <Slide>
                <SlideTitle isActive={activeSlide === idx}>
                  {item.getTitle()}
                </SlideTitle>
                <SlideDate>{item.date}</SlideDate>
                <Line />
              </Slide>
            </SwiperSlide>
          ))}
        </Swiper>
        <Text>
          {text}{' '}
          {link !== '' && (
            <a href={link} target={'_blank'} rel="noreferrer">
              {' '}
              Link
            </a>
          )}
        </Text>
      </Container>
    </Root>
  )
}

export default History

const Root = styled.div`
  position: relative;
  z-index: 2;
  padding-top: 188px;
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 148px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 61px;
  }
  ${Title} {
    margin-bottom: 24px;
    ${({ theme }) => theme.adaptive.sm} {
      padding-top: 20px;
    }
  }
  ${Description} {
    margin-bottom: 24px;
    ${({ theme }) => theme.adaptive.sm} {
      padding-top: 20px;
    }
  }
  .swiper-slide {
    width: auto !important;
  }
  .swiper-container,
  .swiper {
    overflow: visible;
    cursor: grab;
  }
`

const YearsRow = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 80px;
  ${({ theme }) => theme.adaptive.sm} {
    gap: 8px;
    margin-bottom: 60px;
  }
`

const YearButton = styled.button<{ active: boolean }>`
  position: relative;
  display: block;
  width: 108px;
  height: 56px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ active }) => (active ? '#CE4E17' : '#2b2b2b')};
  border-radius: 40px;
  background: ${({ active }) => (active ? '#CE4E17' : '')};
  ${({ theme }) => theme.adaptive.sm} {
    width: 80px;
    height: 48px;
    border-radius: 170px;
    font-size: 12px;
  }
`

const Slide = styled.div`
  width: auto;
`

const SlideTitle = styled.div<{ isActive: boolean }>`
  padding-right: 158px;
  ${({ theme }) => theme.adaptive.lg} {
    padding-right: 92px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-right: 32px;
  }
  p {
    font-size: 32px;
    line-height: 110%;
    color: ${({ isActive }) => (isActive ? '#CE4E17' : '#fff')};
    margin-bottom: 12px;
    height: 70px;
    ${({ theme }) => theme.adaptive.lg} {
      font-size: 20px;
      height: 44px;
      margin-bottom: 8px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      margin-bottom: 3px;
    }
  }
  span {
    display: block;
  }
`

const SlideDate = styled.div`
  font-family: 'Roboto';
  color: #666666;
  font-size: 21px;
  line-height: 150%;
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 14px;
  }
`

const Line = styled.div`
  position: relative;
  height: 10px;
  width: 100%;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  margin-top: 60px;
  &:before {
    content: '';
    position: absolute;
    height: 40px;
    width: 1px;
    background: #fff;
    bottom: 100%;
    left: 0;
  }
`

const Text = styled.div`
  font-family: 'Roboto';
  width: 100%;
  font-weight: 400;
  font-size: 1.313rem;
  line-height: 150%;
  margin-top: 24px;
  color: #ffffff;
  height: 63px;

  ${({ theme }) => theme.adaptive.lg} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    height: 170px;
  }
  a {
    color: #ce4e17;
  }
`
