import { ReactComponent as TwitterLogo } from 'img/socials/twitter.svg'
import { ReactComponent as RedditLogo } from 'img/socials/reddit.svg'
import { ReactComponent as TelegramLogo } from 'img/socials/telegram.svg'
import { ReactComponent as DiscordLogo } from 'img/socials/discord.svg'
import { ReactComponent as YoutubeLogo } from 'img/socials/youtube.svg'
import { ReactComponent as DefaultLogo } from 'img/socials/default.svg'

const socialsList = [
  {
    name: 'Twitter',
    value: '50k',
    getLogo: () => <TwitterLogo />,
    link: 'https://twitter.com/ergoplatformorg',
  },
  {
    value: '21k',
    name: 'Reddit',
    getLogo: () => <RedditLogo />,
    link: 'https://reddit.com/r/ergonauts',
  },
  {
    value: '15k',
    name: 'Telegram',
    getLogo: () => <TelegramLogo />,
    link: 'https://t.me/ergochats',
  },
  {
    value: '8,1k',
    name: 'Discord',
    getLogo: () => <DiscordLogo />,
    link: 'https://discord.gg/RC8M4Bkgsy',
  },
  {
    value: '5,2k',
    name: 'Youtube',
    getLogo: () => <YoutubeLogo />,
    link: 'https://www.youtube.com/c/ErgoPlatform',
  },
]

export default socialsList
