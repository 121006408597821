import React from 'react'
import styled, { css } from 'styled-components'
import closeImg from 'img/close.svg'
import authorPhoto from '../../img/mainfest/author.png'
import useWindowSize from '../../helpers/utils/useWindowSize'

interface Props {
  onClose: () => void
}

function AuthorModal(props: Props) {
  const { width } = useWindowSize()

  return (
    <Root>
      <Row>
        <AuthorRow>
          <AuthorImg />
          <Info>
            <Name>Alexander Chepurnoy</Name>
            <Position>Author of Manifesto</Position>
          </Info>
        </AuthorRow>
        {width > 1024 && <Close onClick={props.onClose} />}
      </Row>
      <Text>
        Active in blockchain since 2011, Alexander Chepurnoy (aka Kushti) is the
        core developer of Ergo Platform, where he manages the ecosystem,
        development updates, protocol reference clients, hackathons, and
        regularly participates in Ergo AMAs. Prior to founding Ergo, Kushti
        worked for IOHK (the research arm of Cardano) as a researcher and helped
        create smartcontract.com (now Chainlink). He has numerous academic
        research papers to his name and is highly regarded as an innovator in
        the blockchain industry
      </Text>
      {width < 1024 && <Close onClick={props.onClose} />}
    </Root>
  )
}

export default AuthorModal

const Root = styled.div`
  position: relative;
  width: 934px;
  ${({ theme }) => theme.adaptive.sm} {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 335px;
    height: 100vh;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.adaptive.sm} {
    ddisplay: block;
    width: 100%;
  }
`

const Close = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url('${closeImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
`

const AuthorRow = styled.div`
  display: flex;
  align-items: center;
`

const AuthorImg = styled.div`
  width: 60px;
  height: 60px;
  background: url('${authorPhoto}') center no-repeat;
  background-size: cover;
  margin-right: 12px;
  ${({ theme }) => theme.adaptive.lg} {
    width: 53px;
    height: 53px;
  }
`

const Info = styled.div``

const InfoTextCss = css`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.313rem;
  line-height: 150%;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 0.75rem;
  }
`

const Name = styled.div`
  ${InfoTextCss};
`

const Position = styled.div`
  ${InfoTextCss};
  opacity: 0.5;
`

const Text = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 40px;
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 20px;
    font-size: 16px;
  }
`
