import React, { useState } from 'react'
import styled from 'styled-components'
import Header from 'components/header'
import Main from 'components/main'
import Manifest from 'components/manifest'
import History from 'components/history'
import About from 'components/about'
import Socials from 'components/socials'
import Footer from '../footer'
import Auditory from '../auditory'
import ContactForm from '../modals/ContactForm'

function Index() {
  const [isOpen, setIsOpen] = useState(false)

  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <Root>
      <ContactForm active={isOpen} onClose={onClose} />
      <Header />
      <Main />
      <Manifest open={() => setIsOpen(true)} />
      <About />
      <History />
      <Auditory />
      <Socials />
      <Footer />
    </Root>
  )
}

export default Index

const Root = styled.div`
  font-family: 'Vinila Extended';
  position: relative;
  width: 100%;
  background: #141414;
  overflow: hidden;
`
