import React, {
  TouchEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import titleImg from 'img/header/title-img.png'
import img from 'img/main/img.png'
import quoteImg from 'img/main/quoteImg.svg'
import gsap from 'gsap'
import quotesList from 'store/quotes'
import useWindowSize from 'helpers/utils/useWindowSize'
import authorPhoto from 'img/main/founder.png'

function Main() {
  const titleRef = useRef(null)
  const listRef = useRef(null)
  const cardsWrapRef = useRef(null)
  const imgRef = useRef(null)
  const ItemRef = useRef<HTMLElement[]>([])
  ItemRef.current = []
  const [startPos, setStartPos] = useState(0)
  const [xPos, setXPos] = useState(0)
  const [animStart, setAnimStart] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [isBg, setIsBg] = useState(false)
  const [resizeTimeout, setReszieTimeout] = useState<any>(null)
  const { width } = useWindowSize()

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(resizeTimeout)
      setReszieTimeout(
        setTimeout(() => {
          if (listRef.current && ItemRef.current) {
            setXPos(0)
            gsap.to(listRef.current, {
              duration: width < 1024 ? 0 : 0.8,
              opacity: 1,
              width: ItemRef.current[0].offsetWidth * ItemRef.current.length,
              transform: '',
              onComplete: () => {
                let precent = 100 / ItemRef.current.length
                setIsBg(true)
                ItemRef.current.forEach((el, idx) => {
                  gsap.to(el, {
                    left: `${precent * idx}%`,
                    duration: width < 1024 ? 0 : 0.8,
                  })
                })
              },
            })
          }
          if (width > 1024) {
            if (titleRef.current) {
              gsap.to(titleRef.current, {
                duration: 0.8,
                top: 48,
              })
            }
            if (imgRef.current) {
              gsap.to(imgRef.current, {
                duration: 0.8,
                transform: 'translateX(-50%) rotate(30deg)',
                onComplete: () => {
                  gsap.to(imgRef.current, {
                    duration: 0.8,
                    transform: 'translateX(-127%) rotate(60deg)',
                  })
                },
              })
            }
          }
        }, 100)
      )
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [width])

  const addToItemRefs = (el: any) => {
    // @ts-ignore
    if (el && !ItemRef.current.includes(el)) {
      // @ts-ignore
      ItemRef.current.push(el)
    }
  }

  const truncateByChars = (str: string, max: number) => {
    return str.length > max ? str.substring(0, max) + '...' : str
  }

  const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setStartPos(e.touches[0].clientX)
  }

  const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    setStartPos(e.pageX)
    setIsStart(true)
  }

  const preventClick = (event: any) => {
    event.preventDefault()
    event.stopImmediatePropagation()
  }

  const onMouseUp = (e: MouseEvent<HTMLDivElement>) => {
    let drag = false
    if (!animStart && isStart) {
      if (Math.abs(e.pageX - startPos) > 0) {
        drag = true
      }
      if (Math.abs(e.pageX - startPos) > 50) {
        console.log('ds')
        if (e.pageX - startPos < 0) {
          left()
        } else {
          right()
        }
      }
    }
    if (drag) {
      e.target.addEventListener('click', preventClick)
      setTimeout(() => {
        e.target.removeEventListener('click', preventClick)
      }, 100)
    } else e.target.removeEventListener('click', preventClick)
  }

  const touchMove = (e: TouchEvent<HTMLDivElement>) => {
    if (!animStart) {
      if (Math.abs(e.touches[0].clientX - startPos) > 50) {
        if (e.touches[0].clientX - startPos < 0) {
          //left
          left()
        } else {
          right()
        }
      }
    }
  }

  const changeSlide = (
    list: gsap.TweenTarget,
    itemWidth: number,
    left: boolean
  ) => {
    if (left) {
      gsap.to(list, {
        onStart: () => {
          setAnimStart(true)
        },
        duration: 0.4,
        x: xPos - itemWidth,
        onComplete: () => {
          setXPos(xPos - itemWidth)
          setAnimStart(false)
        },
      })
    } else {
      gsap.to(list, {
        onStart: () => {
          setAnimStart(true)
        },
        duration: 0.4,
        x: xPos + itemWidth,
        onComplete: () => {
          setXPos(xPos + itemWidth)
          setAnimStart(false)
        },
      })
    }
  }

  const left = () => {
    if (ItemRef.current[0]) {
      let maxPos = -(
        ItemRef.current[0].offsetWidth *
        (ItemRef.current.length - 1)
      )
      if (xPos > maxPos) {
        changeSlide(listRef.current, ItemRef.current[0].offsetWidth, true)
      }
    }
  }

  const right = () => {
    console.log(xPos)
    if (ItemRef.current[0]) {
      if (xPos < 0) {
        changeSlide(listRef.current, ItemRef.current[0].offsetWidth, false)
      }
    }
  }

  return (
    <Root>
      <Container>
        <Img ref={imgRef} />
        <TitleWrap ref={titleRef}>
          <TitleImg />
          <Title>Ergo's Third Anniversary Since Mainnet Launch</Title>
          <SubTitle>
            As we celebrate the third anniversary of the Ergo Platform, let's
            take a look at all that we have achieved together since July 1st,
            2019.
          </SubTitle>
        </TitleWrap>
        <CardsWrapper>
          <CardList
            onTouchStart={onTouchStart}
            onTouchMove={touchMove}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            ref={listRef}
          >
            {quotesList.map((quote, idx) => {
              return (
                <CardWrap ref={addToItemRefs} key={idx}>
                  <Card href={quote.link} target={'_blank'} isBg={isBg}>
                    <TextBlock>
                      <CardIcon />
                      <Text>{truncateByChars(quote.text, 130)}</Text>
                    </TextBlock>
                    <SocialIcon socialName={quote.socialName}>
                      {quote.getLogo()}
                    </SocialIcon>
                  </Card>
                </CardWrap>
              )
            })}
          </CardList>
          <AuthorRow>
            <AuthorImg />
            <Info>
              <Name>Charles Hoskinson Quotes</Name>
              <Position>Founder of Cardano</Position>
            </Info>
          </AuthorRow>
        </CardsWrapper>
      </Container>
    </Root>
  )
}

export default Main

const Root = styled.div`
  position: relative;
`

const TitleWrap = styled.div`
  position: absolute;
  width: 100%;
  top: 413px;
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 19px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: relative;
    top: 0 !important;
    margin-top: 40px;
  }
`

const TitleImg = styled.div`
  position: relative;
  width: 104px;
  height: 120px;
  margin: 0 auto;
  background: url('${titleImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.lg} {
    width: 74px;
    height: 86px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 74px;
    height: 86px;
  }
`

const Title = styled.div`
  position: relative;
  font-family: 'Vinila Extended';
  font-weight: 700;
  font-size: 5.75rem;
  line-height: 110%;
  text-align: center;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 3rem;
    margin-top: 15px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 1.875rem;
  }
`

const SubTitle = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 1.313rem;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  margin-top: 24px;
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 1.313rem;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 0.875rem;
    margin-top: 20px;
  }
`

const Img = styled.div`
  position: absolute;
  top: -264px;
  left: 50%;
  transform: translateX(-50%);
  width: 1175px;
  height: 1175px;
  background: url('${img}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.sm} {
    left: -555px;
    top: -264px;
    transform: rotate(60deg) !important;
    width: 759px;
    height: 759px;
  }
`

const CardList = styled.div`
  position: relative;
  top: 464px;
  left: 0;
  display: flex;
  z-index: 2;
  opacity: 0;
  ${({ theme }) => theme.adaptive.lg} {
    top: 417px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    opacity: 1;
    top: unset;
    margin-top: 20px;
  }
  div {
    user-select: none;
  }
`

const Card = styled.a<{ isBg: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 545px;
  height: 341px;
  background: ${({ isBg }) => (isBg ? '#384044 !important' : '#384044')};
  border-radius: 40px;
  padding: 50px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  user-select: none;
  -webkit-user-drag: none;
  transition: background-color 0.4s;
  ${({ theme }) => theme.adaptive.lg} {
    width: 306px;
    height: 262px;
    padding: 32px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 306px;
    height: 262px;
    padding: 32px;
  }
`

const CardWrap = styled.div`
  position: absolute;
  padding-right: 32px;
  top: 0;
  left: 462px;
  z-index: 6;
  ${({ theme }) => theme.adaptive.lg} {
    left: 462px;
    padding-right: 20px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    left: 0;
  }
  &:nth-child(2) {
    ${Card} {
      background: #2f383c;
    }
    left: 492px;
    z-index: 5;
  }
  &:nth-child(3) {
    ${Card} {
      background: #202729;
    }
    left: 522px;
    z-index: 4;
  }
  &:nth-child(4) {
    ${Card} {
      background: #283336;
    }
    left: 555px;
    z-index: 3;
  }
  &:nth-child(5) {
    ${Card} {
      background: #151b1e;
    }
    left: 585px;
    z-index: 2;
  }
  &:nth-child(6) {
    ${Card} {
      background: #384044;
    }
    left: 615px;
    z-index: 1;
  }
`

const TextBlock = styled.div``

const CardIcon = styled.div`
  width: 38px;
  height: 27px;
  margin-bottom: 25px;
  background: url('${quoteImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.lg} {
    width: 34px;
    height: 24px;
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 34px;
    height: 24px;
    margin-bottom: 20px;
  }
`

const Text = styled.div`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 110%;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 1rem;
  }
`

const SocialIcon = styled.div<{ socialName: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  max-height: 32px;
  ${({ theme }) => theme.adaptive.lg} {
    width: 24px;
    height: 24px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 24px;
    height: 24px;
  }
  svg {
    stroke: ${({ socialName }) => (socialName === 'ct' ? '' : '#ffffff')};
    transition: 0.4s;
    max-width: ${({ socialName }) =>
      socialName === 'reddit' ? '32px' : '22px'};
    /* max-height: 20px; */
  }
`

const CardsWrapper = styled.div`
  cursor: grab;
`

const AuthorRow = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 847px;
  ${({ theme }) => theme.adaptive.lg} {
    top: 703px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: relative;
    padding-top: 282px;
    top: unset;
  }
`

const AuthorImg = styled.div`
  width: 60px;
  height: 60px;
  background: url('${authorPhoto}') center no-repeat;
  background-size: cover;
  margin-right: 12px;
  border-radius: 50%;
  ${({ theme }) => theme.adaptive.lg} {
    width: 53px;
    height: 53px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 42px;
    height: 42px;
  }
`

const Info = styled.div``

const InfoTextCss = css`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.313rem;
  line-height: 150%;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 0.75rem;
  }
`

const Name = styled.div`
  ${InfoTextCss};
`

const Position = styled.div`
  ${InfoTextCss};
  opacity: 0.5;
`
