import styled from 'styled-components'

const theme = {
  adaptive: {
    lg: '@media screen and (max-width: 1439px)',
    sm: '@media screen and (max-width: 1030px)',
  },
}

export const Container = styled.div`
  position: relative;
  width: 1400px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.lg} {
    width: 1030px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 335px;
  }
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 5.75rem;
  line-height: 110%;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 3rem;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 1.875rem;
  }
`

export const Description = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.313rem;
  line-height: 150%;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 0.875rem;
  }
`

export default theme
