const historyList = [
  {
    getTitle: () => (
      <p>
        Ergo Mainnet<span>launch</span>
      </p>
    ),
    date: '1 July 2019',
    text: ' This release includes hard-coded proof-of-no-premine and genesis state root hash. Thanks to all who made it for mainnet launch',
    link: 'https://explorer.ergoplatform.com',
    year: '2019',
  },
  {
    getTitle: () => (
      <p>
        ERG is listed on <span>7 exchanges</span>
      </p>
    ),
    date: '30 October 2019',
    text: 'Tidex Exchange, Vgate Exchange, Hotbit Exchange, P2PB2B Exchange, Hotbit Exchange, BISQ Exchange, Probit Exchange\n',
    year: '2019',
  },
  {
    getTitle: () => (
      <p>
        Typescript<span>library released</span>
      </p>
    ),
    date: '4 December 2019',
    text: 'The library for Ergo platform, written in TypeScript, and compiled to JavaScript',
    link: 'https://github.com/coinbarn/ergo-ts',
    year: '2019',
  },
  {
    getTitle: () => (
      <p>
        Introducing the<span>Ergo Foundation</span>
      </p>
    ),
    date: '17 January 2020',
    text: 'A community-driven entity.',
    link: 'https://ergoplatform.org/en/foundation/',
    year: '2020',
  },
  {
    getTitle: () => (
      <p>
        ErgoMixer<span>release</span>
      </p>
    ),
    date: '23 March 2020',
    text: 'A powerful mixer that works by pooling funds and enabling participants to spend them without anyone knowing who made the transactions',
    link: 'https://ergoplatform.org/en/blog/2020_03_20_ergo_mixer/',
    year: '2020',
  },
  {
    getTitle: () => (
      <p>
        BPSAA & EMURGO<span>to partner with Ergo</span>
      </p>
    ),
    date: '9 June 2020',
    text: 'Together they Build Blockchain-Based Decentralized Financial Solutions',
    link: 'https://emurgo.io/en/blog/emurgo-to-partner-with-ergo-and-build-blockchain-based-decentralized-financial-solutions',
    year: '2020',
  },
  {
    getTitle: () => (
      <p>
        NFTs on Ergo, 1st Auction<span>House, NFT Marketplace</span>
      </p>
    ),
    date: '16 October 2020',
    text: 'The platform allows users to sell Ergo’s NFTs – an important development in the growing DeFi movement – securely and easily',
    link: 'https://ergoplatform.org/en/blog/2020-10-16-announcing-the-auction-house-nft-marketplace-on-ergo',
    year: '2020',
  },
  {
    getTitle: () => (
      <p>
        AgeUSD<span>stablecoin</span>
      </p>
    ),
    date: '11 February 2021',
    text: 'AgeUSD is a novel crypto-backed stablecoin protocol that has been created on top of the Ergo Blockchain',
    link: 'https://ergoplatform.org/en/blog/2021-02-05-building-ergo-how-the-ageusd-stablecoin-works/',
    year: '2021',
  },
  {
    getTitle: () => <p>SigmaUSD</p>,
    date: '26 February 2021',
    text: 'Ergo’s Community Deployed Stablecoin Is Live// The SigmaUSD stablecoin has been deployed by the community',
    link: 'https://ergoplatform.org/en/blog/2021_02_26-sigmausd-released/',
    year: '2021',
  },
  {
    getTitle: () => (
      <p>
        First hackathon<span>ERGOHACK</span>
      </p>
    ),
    date: '1 May 2021',
    text: 'Our goal at Ergo to create a network for the people, censorship-resistant, resilient, open and free',
    link: 'https://ergoplatform.org/en/blog/2021-06-04-ergo-community-launches-its-first-hackathon-ergohack',
    year: '2021',
  },
  {
    getTitle: () => <p>Ergohack 2</p>,
    date: '8 October 2021',
    text: 'Ergohack',
    link: 'https://ergoplatform.org/en/blog/2021-09-07-ergohack-ii',
    year: '2021',
  },
  {
    getTitle: () => (
      <p>
        Ergo & Kairon<span>Labs Partnership</span>
      </p>
    ),
    date: '3 December 2021',
    text: "Kairon will focus on Ergo's liquidity and trading volume as we work towards future exchange listings",
    link: 'https://finance.yahoo.com/news/ergo-partners-kairon-labs-093300165.html',
    year: '2021',
  },
  {
    getTitle: () => (
      <p>
        Ergo Foundation Announces<span>Official Incorporation</span>
      </p>
    ),
    date: '10 December 2021',
    text: 'he Accounting and Corporate Regulatory Authority ([ACRA](https://www.acra.gov.sg/)) has approved Ergo Foundation Ltd as a corporate entity in Singapore.',
    link: 'https://ergoplatform.org/en/blog/2021-12-10-ergo-foundation-announces-official-incorporation/',
    year: '2021',
  },
  {
    getTitle: () => (
      <p>
        Ergohack 3:<span>Privacy and Security</span>
      </p>
    ),
    date: '25 january 2022',
    text: 'The Ergo Platform is teeming with activity from the numerous projects that have been, and continue to be, developed from previous ErgoHack’s.',
    link: 'https://ergoplatform.org/en/blog/2021-12-21-ergohack-iii-privacy-security',
    year: '2022',
  },
  {
    getTitle: () => (
      <p>
        Ergo's new<span>website launch</span>
      </p>
    ),
    date: '3 May 2022',
    text: 'Ergo website underwent a complete redesign',
    link: 'https://ergoplatform.org/en/blog/ergos-new-website',
    year: '2022',
  },
  {
    getTitle: () => (
      <p>
        ErgoHack<span>Fest</span>
      </p>
    ),
    date: '9-30 May 2022',
    text: 'ErgoHack Fest is co-presented by the Ergo Foundation and ErgoPad',
    link: 'https://ergoplatform.org/en/blog/ErgoHack-Fest-May-9-30',
    year: '2022',
  },
  {
    getTitle: () => (
      <p>
        A New Era for Ergo:<span>EIP-27</span>
      </p>
    ),
    date: '22 July 2022',
    text: 'Adjustment to the emission schedule of ERG.',
    link: 'https://ergoplatform.org/en/blog/A-New-Era-for-Ergo-EIP-27-Begins-Today',
    year: '2022',
  },
]

export default historyList
