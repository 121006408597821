import React, { useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { noop } from 'lodash'
import AuthorModal from '../manifest/modal'

export interface ModalProps {
  active?: boolean
  onClose: () => void
  children?: React.ReactNode
}

const portal = document.getElementById('root')

function ModalTemplate(props: ModalProps) {
  const { active = false, onClose = noop, children = null } = props

  const modalWindow = useRef(null)

  if (!portal || !active) {
    return null
  }

  return createPortal(
    <ModalTemplateRoot>
      <Modal ref={modalWindow}>
        <AuthorModal onClose={props.onClose} />
      </Modal>
    </ModalTemplateRoot>,
    portal
  )
}

export default ModalTemplate

const ModalTemplateRoot = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: rgba(56, 64, 68, 0.96);
`

const Modal = styled.div`
  position: relative;
`
