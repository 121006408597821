import React from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import logoImg from 'img/header/logo.svg'
import gradientImg from 'img/header/gradient.png'
import { HashLink as Link } from 'react-router-hash-link'
import Slider from 'react-slick'
import useWindowSize from 'helpers/utils/useWindowSize'

function Header() {
  const { width } = useWindowSize()
  const Desktop = (
    <>
      <Logo />
      <Nav>
        <NavItem>
          <Link to={'#manifesto'}>Ergo’s Manifesto</Link>
        </NavItem>
        <NavItem>
          <Link to={'#about'}>What is about</Link>
        </NavItem>
        <NavItem>
          <Link to={'#history'}>History</Link>
        </NavItem>
        <NavItem>
          <Link to={'#auditory'}>Auditory</Link>
        </NavItem>
      </Nav>
      <Button>
        <a href={'https://ergoplatform.org/en/'} target={'_blank'}>
          Core site
        </a>
      </Button>
    </>
  )

  const settings = {
    centerMode: false,
    infinite: false,
    arrows: false,
    swipeToSlide: true,
    variableWidth: true,
  }

  const Mobile = (
    <>
      <Logo />
      <Slider {...settings}>
        <NavItem>
          <Link to={'#manifesto'}>Ergo’s Manifesto</Link>
        </NavItem>
        <NavItem>
          <Link to={'#about'}>What is about</Link>
        </NavItem>
        <NavItem>
          <Link to={'#history'}>History</Link>
        </NavItem>
        <NavItem>
          <Link to={'#auditory'}>Auditory</Link>
        </NavItem>
        <Button>
          <a href={'https://ergoplatform.org/en/'} target={'_blank'}>
            Core site
          </a>
        </Button>
      </Slider>
    </>
  )

  return (
    <Root>
      <Container>{width > 1024 ? Desktop : Mobile}</Container>
    </Root>
  )
}

export default Header

const Root = styled.div`
  position: relative;
  z-index: 2;
  ${Container} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 58px;
    ${({ theme }) => theme.adaptive.sm} {
      display: block;
      padding-top: 32px;
    }
  }
  .slick-slide {
    max-width: 170px;
    padding-right: 12px;
    &:nth-child(4) {
      padding-right: 24px;
    }
  }
  .slick-track {
    display: flex;
  }
  &:before {
    content: '';
    position: absolute;
    width: 826px;
    height: 961px;
    top: 0;
    right: 0;
    background: url('${gradientImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.lg} {
      right: -331px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 375px;
      height: 436px;
      right: -143px;
    }
  }
`

const Logo = styled.div`
  width: 107px;
  height: 41px;
  background: url('${logoImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.sm} {
    width: 84px;
    height: 32px;
    margin-bottom: 22px;
  }
`

const Nav = styled.div`
  display: flex;
  align-items: center;
`

const LinkCss = css`
  font-family: 'Vinila Extended';
  display: block;
  padding: 16px 24px;
  border-radius: 170px;
  color: #ffffff;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 133%;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 1rem;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding: 16px 20px;
    font-size: 0.75rem;
  }
`

const NavItem = styled.div`
  margin: 0 12px;
  ${({ theme }) => theme.adaptive.lg} {
    margin: 0 6px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin: 0;
  }
  a {
    ${LinkCss};
    border: 1px solid rgba(255, 255, 255, 0.1);
    &:hover {
      background: #ce4e17;
      border: 1px solid #ce4e17;
    }
  }
`

const Button = styled.div`
  position: relative;
  a {
    ${LinkCss};
    background: #ce4e17;
    border: 1px solid #ce4e17;
    &:hover {
      background: transparent;
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
`
