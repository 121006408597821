import React from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import bottomBg from 'img/about/bottom-bg.svg'
import bottomBgSm from 'img/about/bottom-bg-sm.svg'
import cardList from 'store/cards'
import Card from './card'
import useWindowSize from 'helpers/utils/useWindowSize'
import Slider from 'react-slick'

function About() {
  const { width } = useWindowSize()

  const Cards = cardList.map((item, idx) => {
    return (
      <CardWrapper key={idx}>
        <Card idx={idx} text={item.text} />
      </CardWrapper>
    )
  })

  const settings = {
    centerMode: false,
    infinite: false,
    arrows: false,
    swipeToSlide: true,
    variableWidth: true,
  }

  const Mobile = <Slider {...settings}>{Cards}</Slider>

  return (
    <Root id={'about'}>
      <Container>
        {width > 1439 && Cards}
        <Bg />
        <TitleWrap>
          <Title>What is it about</Title>
          <SubTitle>
            But, let’s speak in details. Hover to card to read some facts about
            us
          </SubTitle>
        </TitleWrap>
        {width <= 1439 && Mobile}
      </Container>
    </Root>
  )
}

export default About

const Root = styled.div`
  margin-top: 144px;
  position: relative;
  background: #6b3016;
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 130px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 600px;
    padding-bottom: 19px;
  }
  .slick-slide {
    max-width: 318px;
    padding-right: 12px;
  }
  .slick-track {
    display: flex;
  }
`

const TitleWrap = styled.div`
  padding-top: 399px;
  padding-bottom: 406px;
  width: 635px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-bottom: 20px;
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 5.75rem;
  line-height: 110%;
  text-align: center;
  color: #ffffff;
  width: 505px;
  margin: 0 auto;
  margin-bottom: 24px;
  ${({ theme }) => theme.adaptive.lg} {
    width: 260px;
    font-size: 3rem;
    margin: 0 0 24px 0;
    text-align: left;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    font-size: 1.875rem;
    margin-bottom: 20px;
  }
`

const SubTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.313rem;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.lg} {
    width: 100%;
    text-align: left;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 0.875rem;
  }
`

const CardCss = css`
  position: absolute;
  width: 306px;
  height: 368px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.lg} {
    position: relative;
    width: 306px !important;
    top: unset !important;
    left: unset !important;
  }
  ${({ theme }) => theme.adaptive.sm} {
    height: 306px;
  }
`

const CardWrapper = styled.div`
  ${CardCss};
  &:nth-child(1) {
    top: 81px;
    left: 0;
  }
  &:nth-child(2) {
    top: 695px;
    left: 362px;
  }
  &:nth-child(3) {
    top: 157px;
    right: 0;
  }
  &:nth-child(4) {
    top: -57px;
    left: 362px;
  }
  &:nth-child(5) {
    top: 525px;
    left: 0;
  }
  &:nth-child(6) {
    top: 640px;
    right: 0;
  }
  &:nth-child(7) {
    top: 1px;
    left: 727px;
  }
  &:nth-child(8) {
    top: 760px;
    left: 727px;
  }
`

const Bg = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -297px;
  width: 2136px;
  height: 297px;
  background: url('${bottomBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.lg} {
    bottom: unset;
    top: 560px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 1821px;
    height: 200px;
    top: 440px;
    left: -1177px;
    transform: unset;
    background: url('${bottomBgSm}') center no-repeat;
    background-size: cover;
  }
`
