import React from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import logoImg from 'img/header/logo.svg'
import gradientImg from 'img/header/gradient.png'
import { Link } from 'react-router-dom'

function Footer() {
  const Desktop = (
    <>
      <LogoWrap>
        <Logo />
        <CopyRight>2019—2022</CopyRight>
      </LogoWrap>
      <Nav>
        <NavItem>
          <a
            href={'https://ergoplatform.org/docs/whitepaper.pdf'}
            target={'_blank'}
          >
            Whitepaper
          </a>
        </NavItem>
        <NavItem>
          <a href={'https://github.com/ergoplatform'} target={'_blank'}>
            Github
          </a>
        </NavItem>
        <NavItem>
          <a href={'https://docs.ergoplatform.com/'} target={'_blank'}>
            Documentation
          </a>
        </NavItem>
      </Nav>
      <Button>
        <a href={'https://ergoplatform.org/en/'} target={'_blank'}>
          Core site
        </a>
      </Button>
    </>
  )

  return (
    <Root>
      <Container>{Desktop}</Container>
    </Root>
  )
}

export default Footer

const Root = styled.div`
  position: relative;
  padding-top: 86px;
  padding-bottom: 84px;
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 108px;
    padding-bottom: 92px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  ${Container} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => theme.adaptive.sm} {
      display: block;
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 826px;
    height: 961px;
    bottom: 0;
    left: 0;
    background: url('${gradientImg}') center no-repeat;
    background-size: cover;
    transform: rotate(180deg);
    ${({ theme }) => theme.adaptive.lg} {
      left: -331px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 375px;
      height: 436px;
      left: -143px;
    }
  }
`

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
    margin-bottom: 30px;
  }
`

const Logo = styled.div`
  width: 107px;
  height: 41px;
  background: url('${logoImg}') center no-repeat;
  background-size: cover;
  margin-right: 12px;
  ${({ theme }) => theme.adaptive.sm} {
    margin-right: 0;
    margin-bottom: 10px;
  }
`

const CopyRight = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #666666;
`

const Nav = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
  }
`

const LinkCss = css`
  color: #ffffff;
  font-weight: 400;
  font-size: 1.125rem;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 1rem;
  }
`

const NavItem = styled.div`
  margin: 0 24px;
  ${({ theme }) => theme.adaptive.sm} {
    margin: 0 0 20px 0;
  }
  a {
    ${LinkCss};
    &:hover {
      color: #ce4e17;
    }
  }
`

const Button = styled.div`
  position: relative;
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 30px;
  }
  a {
    ${LinkCss};
    &:hover {
      color: #ce4e17;
    }
  }
`
