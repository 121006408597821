import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import titleImg from 'img/header/title-img.png'
import gsap from 'gsap'

interface Props {
  idx: number
  text: { p: string }[]
}

function Card(props: Props) {
  const cardRef = useRef(null)
  const [isFlipped, setIsFlipped] = useState(false)

  const onClickHandler = () => {
    if (!cardRef.current) return
    let transformString = ''
    if (!isFlipped) transformString = 'translateX(-100%) rotateY(-180deg)'
    else transformString = ''
    gsap.to(cardRef.current, {
      duration: 0.5,
      transform: transformString,
      onComplete: () => {
        setIsFlipped(!isFlipped)
      },
    })
  }
  return (
    <Root ref={cardRef} onClick={onClickHandler}>
      <CardFaceFront>
        <CardNumber>{props.idx + 1}</CardNumber>
        <CardIcon />
      </CardFaceFront>
      <CardFaceBack>
        <CardIcon />
        {props.text.map((text, idx) => {
          return <CardText key={idx}>{text.p}</CardText>
        })}
      </CardFaceBack>
    </Root>
  )
}

export default Card

const Root = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center right;
`

const CardFaceCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 20px;
`

const CardFaceFront = styled.div`
  ${CardFaceCss};
  background: #833b1b;
`

const CardFaceBack = styled.div`
  ${CardFaceCss};
  background: #ce4e17;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 20px;
  ${({ theme }) => theme.adaptive.sm} {
    padding: 20px 20px;
  }
`

const CardNumber = styled.div`
  position: absolute;
  left: 36px;
  bottom: 32px;
  font-weight: 600;
  font-size: 5.75rem;
  line-height: 110%;
  text-align: center;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 3rem;
  }
  ${({ theme }) => theme.adaptive.sm} {
    left: 38px;
    bottom: 35px;
  }
`

const CardIcon = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 52px;
  height: 60px;
  background: url('${titleImg}') center no-repeat;
  background-size: cover;
`

const CardText = styled.p`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 1.063rem;
  line-height: 150%;
  color: #ffffff;
  margin-top: 24px;
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 20px;
    font-size: 0.938rem;
  }
`
