import React from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import socialsList from 'store/socials'
import useWindowSize from 'helpers/utils/useWindowSize'
import Slider from 'react-slick'

function Socials() {
  const { width } = useWindowSize()

  const settings = {
    centerMode: false,
    infinite: false,
    arrows: false,
    swipeToSlide: true,
    variableWidth: true,
  }

  const Items = socialsList.map((item, idx) => {
    return (
      <Item href={item.link} target={'_blank'} key={idx}>
        <Icon>{item.getLogo()}</Icon>
        <Info>
          <Value>{item.value}</Value>
          <Name>{item.name}</Name>
        </Info>
      </Item>
    )
  })

  const Mobile = <Slider {...settings}>{Items}</Slider>

  return (
    <Root>
      <Container>
        {width > 1439 && <Row>{Items}</Row>}
        {width <= 1439 && Mobile}
      </Container>
    </Root>
  )
}

export default Socials

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: auto;
  svg {
    width: 100%;
    height: auto;
    transition: 0.4s;
    stroke: #fff;
  }
`

const Item = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 254px;
  height: 254px;
  padding: 32px;
  background: #384044;
  border: 1px solid #141414;
  border-radius: 20px;
  transition: 0.4s;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.lg} {
    display: flex !important;
    width: 254px !important;
  }
  &:nth-child(1) {
    ${Icon} {
      svg {
        width: 36px;
        height: 31px;
      }
    }
  }
  &:hover {
    background: #5daddd;
    border: 1px solid #5daddd;
  }
  &:nth-child(2):hover {
    background: #e1662d;
    border: 1px solid #e1662d;
  }
  &:nth-child(3):hover {
    background: #549fce;
    border: 1px solid #549fce;
  }
  &:nth-child(4):hover {
    background: #7789d4;
    border: 1px solid #7789d4;
  }
  &:nth-child(5):hover {
    background: #d43929;
    border: 1px solid #d43929;
  }
`

const Root = styled.div`
  padding-top: 80px;
  position: relative;
  z-index: 2;
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 32px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-bottom: 40px;
  }
  .slick-slide {
    max-width: 274px;
    padding-right: 20px;
    ${Item} {
      background: #5daddd;
      border: 1px solid #5daddd;
    }
    &:nth-child(2) {
      ${Item} {
        background: #e1662d;
        border: 1px solid #e1662d;
      }
    }
    &:nth-child(3) {
      ${Item} {
        background: #549fce;
        border: 1px solid #549fce;
      }
    }
    &:nth-child(4) {
      ${Item} {
        background: #7789d4;
        border: 1px solid #7789d4;
      }
    }
    &:nth-child(5) {
      ${Item} {
        background: #d43929;
        border: 1px solid #d43929;
      }
    }
  }
  .slick-track {
    display: flex;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Info = styled.div``

const Value = styled.div`
  width: 100%;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 32px;
  line-height: 110%;
  color: #ffffff;
`

const Name = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 21px;
  line-height: 133%;
  color: #ffffff;
`
