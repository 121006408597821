import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ReactComponent as Map } from 'img/auditory/map.svg'
import { Description, Title, Container } from '../../theme/theme'
import useWindowSize from 'helpers/utils/useWindowSize'
import Slider from 'react-slick'
import usaImg from 'img/auditory/usa.svg'
import russiaImg from 'img/auditory/russia.svg'
import chinaImg from 'img/auditory/china.svg'
import canadaImg from 'img/auditory/canada.svg'
import netherlandsImg from 'img/auditory/netherlands.svg'
import ukImg from 'img/auditory/uk.svg'
import brazilImg from 'img/auditory/brazil.svg'
import germanyImg from 'img/auditory/germany.svg'

function Auditory() {
  const mapRef = useRef<HTMLDivElement>(null)
  const { width } = useWindowSize()

  useEffect(() => {
    if (width > 1439) {
      if (mapRef.current) {
        let lands = mapRef.current.querySelectorAll('.active-land')
        let info: Element | null
        lands.forEach((land) => {
          land.addEventListener('mouseenter', () => {
            if (!mapRef.current) return
            let landName = land.classList[1]
            info = mapRef.current.querySelector(`.${landName}-info`)
            if (info) {
              console.log(info)
              info.classList.add('active')
            }
          })
          land.addEventListener('mouseleave', () => {
            if (info) {
              info.classList.remove('active')
            }
          })
        })
      }
    }
  }, [width])

  const infoList = [
    {
      className: 'usa-info',
      value: '21%',
      name: 'United States',
    },
    {
      className: 'brazil-info',
      value: '20%',
      name: 'Brazil',
    },
    {
      className: 'russia-info',
      value: '16%',
      name: 'Russia',
    },
    {
      className: 'china-info',
      value: '15%',
      name: 'China',
    },
    {
      className: 'uk-info',
      value: '8%',
      name: 'Uk',
    },
    {
      className: 'canada-info',
      value: '7%',
      name: 'Canada',
    },
    {
      className: 'germany-info',
      value: '7%',
      name: 'Germany',
    },

    {
      className: 'netherlands-info',
      value: '6%',
      name: 'Netherlands',
    },
  ]

  const settings = {
    centerMode: false,
    infinite: false,
    arrows: false,
    swipeToSlide: true,
    variableWidth: true,
  }

  const Items = infoList.map((land, idx) => {
    return (
      <Info key={idx} className={land.className}>
        <Icon />
        <Block>
          <Value>{land.value}</Value>
          <Name>{land.name}</Name>
        </Block>
      </Info>
    )
  })

  const Mobile = <Slider {...settings}>{Items}</Slider>

  return (
    <Root id={'auditory'}>
      {width > 1439 && (
        <MapWrapper ref={mapRef}>
          <TitleWrap>
            <Title>Auditory</Title>
            <Description>We are thinking worldwide</Description>
          </TitleWrap>
          <Map />
          {Items}
        </MapWrapper>
      )}
      {width <= 1439 && (
        <Container>
          <TitleWrap>
            <Title>Auditory</Title>
            <Description>We are thinking worldwide</Description>
          </TitleWrap>
          {Mobile}
        </Container>
      )}
    </Root>
  )
}

export default Auditory

const Root = styled.div`
  position: relative;
  padding-top: 21px;
  z-index: 2;
  height: 1156px;
  ${({ theme }) => theme.adaptive.lg} {
    height: auto;
    padding-top: 100px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 80px;
  }
  .slick-slide {
    max-width: 274px;
    padding-right: 20px;
  }
  .slick-track {
    display: flex;
  }
  .russia,
  .brazil,
  .china,
  .germany,
  .uk,
  .netherlands,
  .canada,
  .usa {
    path {
      fill: #546269;
    }
    &:hover path {
      fill: #ce4e17;
    }
  }
  ${Title} {
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 100px;
    pointer-events: none;
    ${({ theme }) => theme.adaptive.lg} {
      text-align: left;
      margin-top: 0;
    }
  }
  ${Description} {
    width: 100%;
    text-align: center;
    pointer-events: none;
    ${({ theme }) => theme.adaptive.lg} {
      text-align: left;
    }
  }
`

const TitleWrap = styled.div`
  position: absolute;
  top: 100px;
  width: 100%;
  pointer-events: none;
  ${({ theme }) => theme.adaptive.lg} {
    position: relative;
    top: unset;
    margin-bottom: 50px;
  }
`

const MapWrapper = styled.div`
  position: absolute;
  top: 21px;
  left: 50%;
  transform: translateX(-50%);
  width: 1791px;
  height: 1156px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.lg} {
    width: 100%;
    height: auto;
  }
  svg {
    width: 100%;
    height: 100%;
    transition: 0.4s;
  }
`

const Info = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 20px;
  width: 263px;
  height: 254px;
  top: 61px;
  right: 157px;
  padding: 30px 35px;
  opacity: 0;
  transition: 0.4s;
  pointer-events: none;
  ${({ theme }) => theme.adaptive.lg} {
    position: relative;
    display: flex !important;
    width: 254px !important;
    padding: 28px 32px;
    background: url('${russiaImg}') center no-repeat;
    background-size: cover;
    top: unset !important;
    left: unset !important;
    right: unset !important;
    opacity: 1;
  }
  &.active {
    opacity: 1;
  }
  &.germany-info {
    top: 250px;
    right: 700px;
    ${({ theme }) => theme.adaptive.lg} {
      background: url('${germanyImg}') center no-repeat;
      background-size: cover;
    }
  }
  &.netherlands-info {
    top: 250px;
    right: 750px;
    ${({ theme }) => theme.adaptive.lg} {
      background: url('${netherlandsImg}') center no-repeat;
      background-size: cover;
    }
  }
  &.uk-info {
    top: 220px;
    right: 780px;
    ${({ theme }) => theme.adaptive.lg} {
      background: url('${ukImg}') center no-repeat;
      background-size: cover;
    }
  }
  &.canada-info {
    top: 50px;
    right: unset;
    left: 200px;
    ${({ theme }) => theme.adaptive.lg} {
      background: url('${canadaImg}') center no-repeat;
      background-size: cover;
    }
  }
  &.usa-info {
    top: 350px;
    right: unset;
    left: 350px;
    ${({ theme }) => theme.adaptive.lg} {
      background: url('${usaImg}') center no-repeat;
      background-size: cover;
    }
  }
  &.china-info {
    top: 400px;
    right: 157px;
    ${({ theme }) => theme.adaptive.lg} {
      background: url('${chinaImg}') center no-repeat;
      background-size: cover;
    }
  }
  &.brazil-info {
    top: 600px;
    right: unset;
    left: 300px;
    ${({ theme }) => theme.adaptive.lg} {
      background: url('${brazilImg}') center no-repeat;
      background-size: cover;
    }
  }
`

const Icon = styled.div`
  width: 48px;
  height: 48px;
  background: #7a8184;
  opacity: 0.3;
  border-radius: 50%;
  ${({ theme }) => theme.adaptive.lg} {
    width: 0;
    height: 0;
  }
`

const Block = styled.div``

const Value = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 110%;
  color: #141414;
  margin-bottom: 4px;
  ${({ theme }) => theme.adaptive.lg} {
    color: #ffffff;
  }
`

const Name = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
  color: #141414;
  ${({ theme }) => theme.adaptive.lg} {
    color: #ffffff;
  }
`
